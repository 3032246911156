<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center justify-content-between">
        <h1>لیست زبان های سایت</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="languageList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
          >
            <template #cell(createDate)="data">
              {{ new Date(data.item.createDate).toLocaleDateString("fa-IR") }}
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center" style="gap: 1rem">
                <b-button
                  variant="transparent"
                  size="sm"
                  @click="renderEditModal(data.item)"
                >
                  <feather-icon
                    size="20"
                    icon="EditIcon"
                    class="text-primary"
                  />
                </b-button>
                <b-button
                  variant="transparent"
                  size="sm"
                  @click="renderDeleteModal(data.item)"
                >
                  <feather-icon
                    size="20"
                    icon="TrashIcon"
                    class="text-danger"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  style="transform: rotate(180deg)"
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
      <!-- Delete Modal Start -->
      <b-modal
        id="modal-delete"
        centered
        ok-variant="danger"
        title="حذف زبان"
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteLanguage"
      >
        <template v-if="tempLanguage">
          <span>
            آیا از حذف زبان {{ tempLanguage.persianName }} ({{
              tempLanguage.name
            }}) اطمینان دارید؟
          </span>
        </template>
      </b-modal>
      <!-- Delete Modal End -->
      <!-- Edit Modal Start -->
      <b-modal
        id="modal-edit"
        centered
        ok-variant="success"
        title="ویرایش زبان"
        ok-title="بروزرسانی"
        cancelTitle="انصراف"
        @ok="updateLanguage"
      >
        <template v-if="tempLanguage">
          <b-container fluid>
            <b-form-row>
              <b-col cols="12" md="6">
                <b-form @submit.prevent>
                  <b-form-group label="نام انگلیسی زبان" label-for="name">
                    <b-form-input
                      dir="auto"
                      id="name"
                      trim
                      placeholder="English"
                      v-model="tempLanguage.name"
                    />
                  </b-form-group>
                </b-form>
              </b-col>
              <b-col cols="12" md="6">
                <b-form @submit.prevent>
                  <b-form-group label="نام فارسی زبان" label-for="persianName">
                    <b-form-input
                      id="persianName"
                      trim
                      placeholder="انگلیسی"
                      v-model="tempLanguage.persianName"
                    />
                  </b-form-group>
                </b-form>
              </b-col>
              <b-col cols="12">
                <b-form @submit.prevent>
                  <b-form-group label="آدرس پایه" label-for="preRoute">
                    <b-form-input
                      id="preRoute"
                      trim
                      placeholder="انگلیسی"
                      v-model="tempLanguage.preRoute"
                    />
                  </b-form-group>
                </b-form>
              </b-col>
            </b-form-row>
          </b-container>
        </template>
      </b-modal>
      <!-- Edit Modal End -->
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "لیست زبان ها",
  mounted() {
    this.getAllLanguages();
  },
  data() {
    return {
      isLoading: false,
      languageList: null,
      totalCount: null,
      count: 10,
      pageNumber: 1,
      searchCommand: null,
      tempLanguage: null,
      myTableColumns: [
        {
          key: "languageId",
          label: "شناسه زبان",
        },
        {
          key: "name",
          label: "نام انگلیسی",
        },
        {
          key: "persianName",
          label: "نام فارسی",
        },
        {
          key: "createDate",
          label: "تاریخ ایجاد",
        },
        {
          key: "actions",
          label: "عملیات",
        },
      ],
    };
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllLanguages();
      },
    },
  },
  methods: {
    renderDeleteModal(language) {
      this.tempLanguage = JSON.parse(JSON.stringify(language));
      this.$bvModal.show("modal-delete");
    },
    renderEditModal(language) {
      this.tempLanguage = JSON.parse(JSON.stringify(language));
      this.$bvModal.show("modal-edit");
    },
    async updateLanguage() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateALanguage = new UpdateALanguage(_this);
        updateALanguage.setRequestParamDataObj(_this.tempLanguage);
        await updateALanguage.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `زبان با موفقیت ویرایش شد.`,
              },
            });
            _this.tempLanguage = null;
            _this.getAllLanguages();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteLanguage() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteALanguage = new DeleteALanguage(_this);
        deleteALanguage.setRequestParam({
          id: _this.tempLanguage.languageId,
        });
        await deleteALanguage.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `زبان با موفقیت حذف شد.`,
              },
            });
            _this.getAllLanguages();
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAllLanguages() {
      try {
        let requestParams = {
          count: this.count,
          pageNumber: this.pageNumber,
        };
        if (this.searchCommand) {
          requestParams["searchCommand"] = this.searchCommand;
        }
        this.isLoading = true;
        let _this = this;
        let getAllLangs = new GetAllLangs(_this);

        getAllLangs.setRequestParam(requestParams);
        await getAllLangs.fetch((response) => {
          if (response.isSuccess) {
            _this.languageList = response.data.languages;
            _this.totalCount = response.data.languagesCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BCard,
    BPagination,
    BContainer,
    BFormRow,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
  },
};
import {
  GetAllLangs,
  DeleteALanguage,
  UpdateALanguage,
} from "@/libs/Api/Language";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
</script>
